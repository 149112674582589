import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Pageable } from './pageable';

/**
 * Abstraction of datasource with pagination
 */
export abstract class PageableDataSource<T> extends DataSource<T> {
  public cachedData = Array.from<T>({ length: 0 });
  public currentData: T[] = [];
  public dataStream = new BehaviorSubject<T[]>(this.cachedData);
  private subscription = new Subscription();

  public nextPage = 0;
  public lastPage = 0;
  public hasData = true;
  public loading = false;

  /**
   * The total elements on this
   */
  public totalElements = 0;

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    public limit: number,
    public startPage: number = 0,
  ) {
    super();
    this.fetchPage();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const currentPage = this.getPageByIndex(range.end);

        if (currentPage > this.lastPage) {
          this.lastPage = this.nextPage = currentPage;
          this.fetchPage();
        }
      }),
    );
    return this.dataStream;
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Fetches the data of specific page
   */
  public fetchPage(): void {
    this.loading = true;
    this.loadingSubject.next(true);
    if (this.nextPage === 0 && this.startPage) {
      this.lastPage = this.nextPage = this.startPage;
    }

    this.fetch(this.nextPage, this.limit).subscribe(
      (data) => this.handleData(data),
      () => null, // TODO: error treatment
      () => {
        this.loading = false;
        this.loadingSubject.next(false);
      },
    );
  }

  /**
   * Gets the current page
   * @param i
   */
  private getPageByIndex(i: number): number {
    const page = Math.floor(i / this.limit);
    return page + this.startPage;
  }

  /**
   * Fetches page data
   * @param page
   * @param limit
   */
  public abstract fetch(page: number, limit: number): Observable<Pageable<T>>;

  /**
   * Handles the data to get the sources from super search response
   * @param data
   */
  public handleData(data: Pageable<T>): void {
    if (data.content.length === 0 && this.cachedData.length === 0) {
      this.hasData = false;
    }
    this.totalElements = data.totalElements;
    this.cachedData = this.cachedData.concat(data.content);
    this.currentData = data.content;
    this.dataStream.next(this.cachedData);
  }
}
