import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, OIDC_DEFAULT_SCOPES } from '@azure/msal-browser';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { ApplicationUser } from '../../../../routes/login/model/application-user';

const LOGIN_TYPE = 'login_type';
const LOGIN_TYPE_EMAIL = 'email';
const LOGIN_TYPE_AAD = 'aad';
const ACCESS_TOKEN = 'access_token';
interface LoginResult {
  token: string;
}

/**
 * Service for handling authentication functions.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = `${environment.apiUrl}/auth`;

  /**
   * The logged in user.
   */
  private user: ApplicationUser | null = null;

  private userSubject = new BehaviorSubject<ApplicationUser | null>(null);

  user$: Observable<ApplicationUser | null> = this.userSubject.asObservable();

  /**
   * Observable to identify if user is logged in.
   */
  loggedIn$: Observable<boolean> = this.user$.pipe(map((user) => !!user));

  constructor(
    private router: Router,
    private http: HttpClient,
    private msalAuthService: MsalService,
  ) {}

  /**
   * Returns basic logged user info from server
   */
  getUserInfo(): Observable<ApplicationUser> {
    if (this.user) {
      return of(this.user);
    }

    return this.http.get<ApplicationUser>(this.baseUrl + '/me').pipe(tap((user) => (this.user = user)));
  }

  /**
   * Check if user has a permission.
   * @param name permission name.
   * @returns true if user has permission.
   */
  hasPermission(name: string): boolean {
    return !!this.user?.permissions.includes(name);
  }

  /**
   * Login user in the API. Get the access token from API server
   * and set the token in the local storage and the user data.
   *
   * @param username
   * @param password
   */
  login(username: string, password: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(this.baseUrl, { username, password }).pipe(
      map((login) => {
        localStorage.setItem(ACCESS_TOKEN, login.token);
        localStorage.setItem(LOGIN_TYPE, LOGIN_TYPE_EMAIL);
        this.getUserInfo().subscribe((user) => this.userSubject.next(user));
        return login;
      }),
    );
  }

  /**
   * Initializes the login using Azure active directory.
   *
   * @returns observable that emits when the login proccess finishes
   */
  initiliazeLoginAAD(): Observable<LoginResult> {
    return this.msalAuthService
      .loginPopup({
        scopes: OIDC_DEFAULT_SCOPES,
      })
      .pipe(
        mergeMap((result) => {
          this.checkAndSetActiveAccount();

          const { idToken, accessToken, account } = result;
          const email = account.username;
          return this.loginAAD(email, idToken, accessToken);
        }),
      );
  }

  /**
   * Sets the active account for the msal service (used when refreshing the token).
   */
  private checkAndSetActiveAccount() {
    const activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * Refreshes the user's token.
   *
   * @returns observable that emits when the login proccess finishes
   */
  refreshLogin(): Observable<LoginResult> {
    return this.msalAuthService
      .acquireTokenSilent({
        scopes: OIDC_DEFAULT_SCOPES,
      })
      .pipe(
        catchError((error) => {
          //Acquire token silent failure, send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            return this.msalAuthService.acquireTokenPopup({
              scopes: OIDC_DEFAULT_SCOPES,
            });
          }

          return throwError(() => error);
        }),
        mergeMap((result) => {
          const { accessToken, account } = result;
          const email = account.username;
          return this.refreshLoginAAD(email, accessToken);
        }),
      );
  }

  /**
   * Login user in the API with a Azure Active Directory IdToken. Get the access token from API server
   * and set the token in the local storage and the user data.
   */
  loginAAD(email: string, idToken: string, accessToken: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(this.baseUrl + '/aad', { email, idToken, accessToken }).pipe(
      map((login) => {
        localStorage.setItem(ACCESS_TOKEN, login.token);
        localStorage.setItem(LOGIN_TYPE, LOGIN_TYPE_AAD);
        this.getUserInfo().subscribe((user) => this.userSubject.next(user));
        return login;
      }),
    );
  }

  /**
   * Refreshes the user's application token.
   *
   * @param email the user's email
   * @param accessToken the AAD access token
   * @returns an observable that emits the new access token when the server responds
   */
  private refreshLoginAAD(email: string, accessToken: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(this.baseUrl + '/aad/refresh', { email, accessToken }).pipe(
      map((login) => {
        localStorage.setItem(ACCESS_TOKEN, login.token);
        this.getUserInfo().subscribe((user) => this.userSubject.next(user));
        return login;
      }),
    );
  }

  /**
   * Logout: clear data from local storage, clear user
   * data and navigate back to login page.
   */
  logout(redirectParams?: object): void {
    localStorage.removeItem(ACCESS_TOKEN);
    this.user = null;
    this.userSubject.next(null);
    localStorage.removeItem(LOGIN_TYPE);

    // Do not redirect if already at login page
    if (!this.router.url.startsWith('/login')) {
      this.router.navigate([{ outlets: { sidenav: null, primary: ['login'] } }], redirectParams);
    }
  }

  /**
   * Returns the access token from local storage.
   */
  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  /**
   * Verify if the user is logged in.
   */
  isLoggedIn(): Observable<boolean> {
    if (this.user) {
      return of(!!this.user);
    }

    const token = this.getAccessToken();

    if (token) {
      return this.getUserInfo().pipe(
        tap((user) => {
          this.userSubject.next(user);
        }),
        map((user) => !!user),
        catchError(() => of(false)),
      );
    }

    return of(false);
  }
}
